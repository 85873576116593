/** @format */

import {
  BankOutlined,
  BookOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  GlobalOutlined,
  HomeOutlined,
  HomeTwoTone,
  PauseCircleOutlined,
  ReadOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const menu = [
  {
    key: "1",
    icon: <DashboardOutlined style={{ color: "black" }} />,
    label: "Dashboard",
    route: "/",
    role: [1, 2, 3, 4, 5],
  },
  {
    key: "2",
    icon: <HomeOutlined style={{ color: "black" }} />,
    label: "School",
    route: "/schools",
    role: [1, 2, 3, 4, 5],
  },
  {
    key: "3",
    icon: <GlobalOutlined style={{ color: "black" }} />,
    label: "City",
    route: "/state",
    role: [1, 2, 3, 4, 5],
  },
  {
    key: "4",
    icon: <UserOutlined style={{ color: "black" }} />,
    label: "User Management",
    route: "/user-management",
    role: [1],
  },
  {
    key: "6",
    icon: <ReadOutlined style={{ color: "black" }} />,
    label: "Blogs",
    route: "/blogs",
    role: [1, 2, 3, 4, 5],
  },
  {
    key: "7",
    icon: <FileSearchOutlined style={{ color: "black" }} />,
    label: "SEO",
    route: "/seo",
    role: [1, 2, 3, 4, 5],
  },
  {
    key: "8",
    icon: <FileSearchOutlined style={{ color: "black" }} />,
    label: "Dynamic Faq",
    route: "/dynamic-faq",
    role: [1, 2],
  },
  {
    key: "9",
    icon: <UserOutlined style={{ color: "black" }} />,
    label: "Requested Call",
    route: "/requested-call",
    role: [1],
  },
  {
    key: "10",
    icon: <UploadOutlined style={{ color: "black" }} />,
    label: "Upload File",
    route: "/upload-file",
    role: [1, 2, 3],
  },
  {
    key: "11",
    icon: <BookOutlined style={{ color: "black" }} />,
    label: "Enquiry Details",
    route: "/enquiry-details",
    role: [1, 2],
  },
];
