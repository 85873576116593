import React, { useEffect, useState } from "react";
import Loader from "../../Components/UtilComponents/Loader";
import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import axios from "axios";

const User = () => {
  const [load, setLoad] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editId, setEditId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const userDetails = JSON.parse(
    localStorage.getItem("userDetails")
  )?.user_data;

  const userRole = [
    { role: "SUPERADMIN", id: 1 },
    { role: "ADMIN", id: 2 },
    { role: "SCHOOL_ADMIN", id: 3 },
    { role: "IS_CONTENT_MANAGER", id: 4 },
    { role: "IS_BlOG_AUTHOR", id: 5 },
  ];

  const fetchAllUserList = (page = 1, pageSize = 20) => {
    setLoad(true);
    axios
      .get(`/user/user-list/?page=${page}&page_size=${pageSize}`)
      .then((res) => {
        setUserList(res?.data?.results);
        setPagination({
          ...pagination,
          current: page,
          pageSize: pageSize,
          total: res?.data?.count, // Assuming your API returns total count
        });
      })
      .catch((err) => {
        console.log(err, "hello");
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleTableChange = (newPagination) => {
    fetchAllUserList(newPagination.current, newPagination.pageSize);
  };

  useEffect(() => {
    fetchAllUserList();
  }, []);

  const validateEmailDomain = (_, value) => {
    if (!value) return Promise.resolve();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@orchids.edu.in$/;
    if (!emailRegex.test(value)) {
      return Promise.reject(new Error('Email must end with "@orchids.edu.in"'));
    }
    return Promise.resolve();
  };

  const validateNoSpacesOnly = (_, value) => {
    if (!value || value.trim() === "") {
      return Promise.reject(new Error("This field cannot be blank!"));
    }
    return Promise.resolve();
  };

  const validatePasswordLength = (_, value) => {
    if (value && value.length <= 6) {
      return Promise.reject(
        new Error("Password must be more than 6 characters!")
      );
    }
    return Promise.resolve();
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    Object.keys(values)?.map((key, index) => {
      if (values[key] === undefined || values[key] === null) {
        values[key] = "";
      }
      formData.append(key, values[key]);
    });

    axios
      .post("/user/create-user/", formData)
      .then((res) => {
        message.success("New User Created Successfully");
        fetchAllUserList();
        setOpen(false);
        form.resetFields();
      })
      .catch((err) => {
        message.error("OPPS! Something Went Wrong!");
      });
  };

  const handleEditOpen = (user) => {
    setEditId(user?.id);
    setSelectedUser(user);
    setEditOpen(true);
    editForm.setFieldsValue({
      ...user,
      role: userRole.find((ele) => ele.id === user.role)?.role || "Unknown",
    });
  };

  const handleOk = async () => {
    try {
      const values = await editForm.validateFields();
      // console.log("Form Values:", values);

      // Convert role name to role ID if necessary
      const roleObj = userRole.find((ele) => ele.role === values.role);
      if (roleObj) {
        values.role = roleObj.id;
      }

      const formData = new FormData();
      Object.keys(values)?.forEach((key) => {
        if (values[key] === undefined || values[key] === null) {
          values[key] = "";
        }

        // Only append the password if it is provided
        if (key !== "password" || values[key].trim() !== "") {
          formData.append(key, values[key]);
        }
      });

      axios
        .put(`/user/user-detail/${selectedUser.id}/`, formData)
        .then((res) => {
          message.success("User Successfully Edited");
          setEditOpen(false);
          setSelectedUser(null);
          fetchAllUserList();
          editForm.resetFields();
        })
        .catch((err) => {
          message.error("Oops! Something went wrong!");
        });
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
      message.error("Please correct the errors in the form.");
    }
  };

  const handleDeleteUser = (id) => {
    axios
      .delete(`/user/user-detail/${id}`)
      .then((res) => {
        message.success("User Successfully Deleted");
        fetchAllUserList();
      })
      .catch((err) => {
        message.error("Oops! Something went wrong!");
      });
  };

  const handleCancel = () => {
    setEditOpen(false);
    setSelectedUser(null);
    editForm.resetFields();
  };

  const columns = [
    {
      title: "User Name",
      key: "first_name",
      dataIndex: "first_name",
      fixed: "left",
    },
    {
      title: "User Email",
      key: "email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "User Contact",
      key: "contact",
      dataIndex: "contact",
      align: "center",
    },
    {
      title: "User Role",
      key: "role",
      render: (record) => {
        const roleObj = userRole.find((ele) => ele.id === record.role);
        return roleObj ? roleObj.role : "Unknown";
      },
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          {userDetails?.roles?.id == 1 ? (
            <>
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => handleDeleteUser(record?.id)}
              >
                <Tag role="button" icon={<DeleteOutlined />} color="error">
                  Delete
                </Tag>
              </Popconfirm>
              <Tag
                role="button"
                color="warning"
                icon={<EditOutlined />}
                onClick={() => handleEditOpen(record)}
              >
                Edit
              </Tag>
            </>
          ) : (
            <p>You Are Not Authorized</p>
          )}
        </Space>
      ),
    },
  ];

  const userRoleOptions = userRole?.map((ele) => (
    <Select.Option key={ele?.id} value={ele?.id}>
      {ele?.role}
    </Select.Option>
  ));

  return (
    <div>
      <div className="d-flex justify-content-between my-2">
        <Breadcrumb
          items={[
            {
              href: "/",
              title: <HomeOutlined />,
            },
            {
              title: "User Management",
            },
          ]}
        />
        {userDetails?.roles?.id == 1 && (
          <Button
            onClick={() => {
              setOpen(true);
            }}
            icon={<PlusOutlined className="th-primary" />}
          >
            Add New User
          </Button>
        )}
      </div>
      {load ? (
        <Loader />
      ) : (
        <Table
          loading={load}
          columns={columns}
          dataSource={userList}
          scroll={{ x: "max-content" }}
          pagination={pagination}
          onChange={handleTableChange}
        />
      )}
      <Modal
        title={`Add New User`}
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
        footer={[
          <Button className="mr-2" onClick={() => setOpen(false)}>
            Cancel
          </Button>,
          <Button type="primary" htmlType="submit" form="addNewUserForm">
            {"Add"}
          </Button>,
        ]}
      >
        <Form
          name="addNewUserForm"
          form={form}
          onFinish={onSubmit}
          layout="vertical"
        >
          <Form.Item
            label="User Name"
            name="first_name"
            rules={[
              { required: true, message: "Please enter User Name!" },
              { validator: validateNoSpacesOnly },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="User Email"
            name="email"
            rules={[
              { required: true, message: "Please enter User Email!" },
              { validator: validateEmailDomain },
              { validator: validateNoSpacesOnly },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="User Password"
            name="password"
            rules={[
              { required: true, message: "Please enter User Password!" },
              { validator: validatePasswordLength },
              { validator: validateNoSpacesOnly },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="User Contact"
            name="contact"
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: "Please enter only numeric characters.",
              },
              { max: 10, message: "Please enter up to 10 digits." },
              { required: true, message: "Please enter User Password!" },
              { validator: validateNoSpacesOnly },
            ]}
          >
            <Input type="text" maxLength={10} />
          </Form.Item>
          <Form.Item
            label="User Role"
            name="role"
            rules={[{ required: true, message: "Please Select User Role!" }]}
          >
            <Select>{userRoleOptions}</Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit User"
        open={isEditOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="first_name"
            label="User Name"
            rules={[{ required: true, message: "Please input the user name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="User Email"
            rules={[
              { required: true, message: "Please input the user email!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contact"
            label="User Contact"
            rules={[
              { required: true, message: "Please input the user contact!" },
              {
                pattern: /^[0-9]*$/,
                message: "Please enter only numeric characters.",
              },
            ]}
          >
            <Input type="text" maxLength={10} />
          </Form.Item>
          <Form.Item
            name="role"
            label="User Role"
            rules={[
              { required: true, message: "Please select the user role!" },
            ]}
          >
            <Select>{userRoleOptions}</Select>
          </Form.Item>
          <Form.Item name="password" label="Reset Password">
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default User;
