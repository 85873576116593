// const BASE_URL = process.env.REACT_APP_BASE_URL;

// console.log("env file",process.env.NODE_ENV)

// export { BASE_URL };

const BASE_URL = window.location.href.includes(
    "cms.schoolsuniverse.com"
  )
    ? "https://www.schoolsuniverse.com/qbox/apiV1"
    : "https://school-hive.stage-gke.letseduvate.com/qbox/apiV1";

    console.log("env file",process.env.NODE_ENV)
  
  export { BASE_URL };